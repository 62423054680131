<template>
  <card :title="$t('church.info')">
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('church.created')" />

      <church-form :form="form" :edit="true"></church-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('register') }}
          </v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import ChurchForm from './form'
import { objectToFormData } from 'object-to-formdata'

export default {
  scrollToTop: false,
  components: {
    'church-form': ChurchForm
  },
  metaInfo () {
    return { title: this.$t('settings') }
  },
  data: () => ({
    form: new Form({
      name: '',
        phone: '',
        email: '',
        website: '',
        facebook: '',
        twitter: '',
        instagram: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        logoUrl: '',
        logo: null,
    })
  }),
  computed: mapGetters({
    user: 'auth/user'
  }),

  methods: {
    async create () {
      const { data } = await this.form.submit('post', '/api/v1/user/me/church', {
        // Transform form data to FormData
        transformRequest: [function (data) {
          return objectToFormData(data)
        }]
      });

      this.$store.dispatch('account/updateChurch', { church: data.church })
          .then(() => this.$router.push({ name: 'accounts.church' }))
    }
  }
}
</script>
